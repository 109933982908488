import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UomLineComponent } from './components/uom/uom.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private dialog: MatDialog) {}

  openModal(data: any): void {
    this.dialog.open(UomLineComponent, { 
      disableClose: true,
      data,
    });
  }
  closeModal(): void {
    this.dialog.closeAll();
  }
}