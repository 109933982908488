import { addNavMenuSection } from '@vendure/admin-ui/core'

export default [
  addNavMenuSection(
    {
      id: 'tools',
      label: 'Product tools',
      displayMode: 'settings',
      collapsible: true,
      items: [
        {
          id: 'new-import',
          label: 'New Import Forms',
          routerLink: ['/extensions/import-products/new-import'],
          icon: 'import',
        },
        {
          id: 'import-products',
          label: 'Import Products',
          routerLink: ['/extensions/import-products/import'],
          icon: 'import',
        },
        {
          id: 'update-products',
          label: 'Update Products',
          routerLink: ['/extensions/import-products/update'],
          icon: 'import',
        },
        {
          id: 'update-product-stock',
          label: 'Update Product Stock',
          routerLink: ['/extensions/import-products/stock'],
          icon: 'import',
        },
        {
          id: 'import-mapping',
          label: 'Import Mapping File',
          routerLink: ['/extensions/import-products/mapping'],
          icon: 'import',
        },
      ],
    },
    'catalog',
  ),
]
