import { addActionBarItem } from '@vendure/admin-ui/core'
import gql from 'graphql-tag'


export const runAutoFulfillment = gql`
  query runAutoFulfillment {
    runAutoFulfillment {
      success
      fail
    }
  }
`

export default [
  addActionBarItem({
    id: 'auto-fulfill',
    label: 'Auto fulfill',
    locationId: 'order-list',
    buttonColor: 'primary',
    onClick(event, context) {
        context.dataService.query(runAutoFulfillment).single$.subscribe()
        context.notificationService.success('Fulfillments added successfully')
    },
  }),
]
