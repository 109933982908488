<vdr-page-block>
  <vdr-action-bar>
    <vdr-ab-left></vdr-ab-left>
  </vdr-action-bar>
</vdr-page-block>
<vdr-page-block>
  <div *ngIf="items$ | async as items" class="block table-wrapper">
    <table>
      <thead>
        <th>Name</th>
        <th>Code</th>
        <th class="center">Per</th>
        <th>Cost Basis</th>
        <th>Min Purchase Quantity</th>
        <th>Available Quantity</th>
        <th>Enabled</th>
        <th>Can Break Bulk</th>
        <th>Child unit</th>
        <th class="center">Edit</th>
      </thead>
      <tbody>
        <ng-template ngFor let-item [ngForOf]="items" let-i="index">
          <tr>
            <td>{{item.type.name}}</td>
            <td>{{item.type.code}}</td>
            <td class="center">{{item.per}}</td>
            <td>{{item.costBasis}}</td>
            <td>{{item.minQty}}</td>
            <td>{{item.quantityAvailable}}</td>
            <td class="icon {{ item.enabled ? 'green' : 'red' }}">
              <cds-icon size="24" *ngIf="item.enabled" shape="eye"></cds-icon>
              <cds-icon size="24" *ngIf="!item.enabled" shape="eye-hide"></cds-icon>
            </td>
            <td class="icon {{ item.canBreakBulk ? 'green' : 'red' }}">
              <cds-icon size="24" *ngIf="item.canBreakBulk" shape="check-circle"></cds-icon>
              <cds-icon size="24" *ngIf="!item.canBreakBulk" shape="times"></cds-icon>
            </td>
            <td>{{item.childUnit?.type?.code}}</td>
            <td class="center">
              <button class="button primary" (click)="openEditDialog(item, stockLevelId, item.id)">
                <clr-icon shape="pencil"></clr-icon>
              </button>
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </div>
</vdr-page-block>