import gql from 'graphql-tag';

export const getCompanyListDocument = gql`
  query GetCompanyList($options: CompanyListOptions) {
    companies(options: $options) {
      items {
        id
        createdAt
        updatedAt
        deletedAt
        name
        description
        referralCode
        avatar {
          name
          type
          mimeType
          width
          height
          source
          preview
        }
        nameId
        isApproved
      }
      totalItems
    }
  }
`;

export const approveCompanyDocument = gql`
  mutation ApproveCompany($id: ID!, $isApproved: Boolean!) {
    approveCompany(id: $id, isApproved: $isApproved) {
      id
      isApproved
    }
  }
`;

export const getCompaniesFilter = gql`
  query GetCompaniesFilter {
    companiesFilter {
      id
      name
    }
  }
`;

export const DeleteCompaniesDocument = gql`
  mutation DeleteCompanies($ids: [ID!]!) {
    deleteCompanies(ids: $ids) {
      result
    }
  }
`;