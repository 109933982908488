import gql from 'graphql-tag';
import { UomFragment } from '../uoms/providers';

const CreaetResult = gql`
fragment Result on CreateUomResult {
  ...UomItem
  ... on UomAlreadyExistsError{
    errorCode
    message
  }
  ... on UomTypeNotFoundError {
    errorCode
    message
  }
}
${UomFragment}`

export const CreateUomDocument = gql`
  mutation CreateUomType($input: CreateUomInput!) {
    createUomType(input: $input) {
      ...Result
    }
  }
  ${CreaetResult}
  `;

export const UpdateUomDocument = gql`
  mutation UpdateUomType($id: ID!, $input: UpdateUomInput!) {
    updateUomType(id: $id, input: $input) {
      ...on Uom {
        costBasis
        minQty
        canBreakBulk
        enabled
        per
      }
      ... on UomNotFoundError {
        errorCode
        message
      }
    }
  }`;
