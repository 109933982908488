import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable, Subscription, BehaviorSubject } from 'rxjs'
import { TypedBaseListComponent, SharedModule } from '@vendure/admin-ui/core';
import { GetUomsByStockLocationIdDocument } from './providers';
import { GraphqlService } from '../../graphql.service';

@Component({
  selector: 'uoms',
  templateUrl: './uoms.component.html',
  styleUrls: ['./uoms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule],
})
export class UomsComponent extends TypedBaseListComponent<typeof GetUomsByStockLocationIdDocument, 'uomsByStockLocationId'> {
  @Input() stockLocationId?: string
  @Input() productVariantId?: string
  @Input() stockLevelId?: string
  @Input() uoms: any[]
  @Input() openEditDialog: any
  @Input() updatedData: any
  public uomsSubject = new BehaviorSubject<any[]>([])
  private uomSubscription: Subscription | undefined
  private dataSubscription: Subscription | undefined
  items$: Observable<any[]> = this.uomsSubject.asObservable()

  constructor(private graphqlService: GraphqlService) {
    super();
  }
  
  ngOnInit() {
    this.uomSubscription = this.dataService.query(GetUomsByStockLocationIdDocument, {
      stockLocationId: this.stockLocationId,
      productVariantId: this.productVariantId,
    })
      .mapSingle(item => item)
      .subscribe(({ uomsByStockLocationId }: any) => {
        this.uomsSubject.next(uomsByStockLocationId)
      });
      this.dataSubscription = this.updatedData.subscribe((result: any) => {
        if (result.updatedId) {
          this.update(result.uomId, result.uom)
        } else if (result.stockLevelId && result.stockLevelId == this.stockLevelId) {
          const values = this.uomsSubject.value
          this.uomsSubject.next([...values, result.uom])
        }
      })
  }

  update(id: string, uom: any) {
    const values = this.uomsSubject.value
    const index = values.findIndex(({ id }: any) => id == id)
    if (index > -1 && uom) {
      this.uomsSubject.next([...values].map((item) => {
        if (item.id === id) {
          return {
            ...item,
            ...uom,
          }
        }
        return item
      }))
    }
  }
  ngOnDestroy() {
    this.uomSubscription?.unsubscribe()
    this.dataSubscription?.unsubscribe()
  }

  updateView(id: string, deleted: boolean) {
    const values = [...this.uomsSubject.value]
    this.uomsSubject.next(values.map((uom: any) => ({
      ...uom,
      deletedAt: id != uom.id ? uom.deletedAt : deleted ? new Date() : null
    })))
  }

  onSoftDelete(id: string) {
    const subscription: Subscription = this.graphqlService.softDeleteUom(id).subscribe(
      ({ data: { softDeleteUom } }: any) => {
        this.updateView(id, softDeleteUom.result == 'DELETED')
        subscription.unsubscribe()
      }
    )
  }
  unDelete(id: string) {
    const subscription: Subscription = this.graphqlService.softUnDeleteUom(id).subscribe(
      ({ data: { softUnDeleteUom } }: any) => {
        this.updateView(id, softUnDeleteUom.result == 'DELETED')
        subscription.unsubscribe()
      }
    )
  }

}