import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvancedSearchUiExtensionModule } from './extensions/advanced-search-plugin/advanced-search-ui-extension.module';
import { B2BUiModule } from './extensions/b2b-plugin/b2b-ui.module';
import { StockSupplierUiModule } from './extensions/stock-supplier-plugin/stock-supplier-ui.module';
import { RecurringOrdersUiModule } from './extensions/recurring-orders-plugin/recurring-orders-ui.module';
import { AutoFulfillmentUiModule } from './extensions/auto-fulfullment-plugin/auto-fulfillment-ui.module';
import { ImportProductsUiModule } from './extensions/import-products-plugin/import-products-ui.module';

import SharedProviders_2_0 from './extensions/bulk-unit-of-measure-plugin/providers';
import SharedProviders_4_0 from './extensions/recurring-orders-plugin/providers';
import SharedProviders_6_0 from './extensions/import-products-plugin/providers';


@NgModule({
    imports: [CommonModule, AdvancedSearchUiExtensionModule, B2BUiModule, StockSupplierUiModule, RecurringOrdersUiModule, AutoFulfillmentUiModule, ImportProductsUiModule],
    providers: [...SharedProviders_2_0, ...SharedProviders_4_0, ...SharedProviders_6_0],
})
export class SharedExtensionsModule {}
