import { DeletionResponse } from '@vendure/admin-ui/core';

export interface DeleteCompaniesResponse {
  deleteCompanies: DeletionResponse[];
}

export interface DeleteCompanyLocationsResponse {
  softDeleteCompanyLocations: {
    success: boolean
  }[];
}

export enum RemovalResult {
  REMOVED = 'REMOVED',
  NOT_REMOVED = 'NOT_REMOVED',
}

export interface RemoveCompanyUsers {
  removeCompanyUsers: {
    result: RemovalResult;
    message?: string;
  }[];
}

export interface DeleteCompanyOrdersResponse {
  deleteCompanyOrders: {
    success: boolean
  }[];
}