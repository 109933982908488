import gql from 'graphql-tag';

const locationFragments = gql`
  fragment CompanyLocationFragment on CompanyLocation {
    id
    name
    deaCertified
    clinicCertified
    createdAt
    updatedAt
    deletedAt
    address {
      fullName
      streetLine1
      streetLine2
      city
      province
      postalCode
      phoneNumber
      defaultShippingAddress
      defaultBillingAddress
      customFields
    }
    company {
      id
      name
    }
    mcKessonLocationNumber
    cardinalMedLocationNumber
    cardinalPharmaLocationNumber
  }
`;

export const getCompanyLocationListDocument = gql`
  query GetCompanyLocations($options: CompanyLocationListOptions) {
    companyLocations(options: $options) {
      items {
        ...CompanyLocationFragment
      }
      totalItems
    }
  }
${locationFragments}`;

export const updateComapanyLocationDocument = gql`
  mutation UpdateCompanyLocation($locationId: ID!, $input: UpdateLocationInput!) {
    updateCompanyLocation(locationId: $locationId, input: $input) {
      ...CompanyLocationFragment
    }
  }
${locationFragments}`;

export const getLocationsFilter = gql`
  query GetLocationsFilter {
    locationsFilter {
      id
      name
    }
  }
`;

export const DeleteCompanyLocationsDocument = gql`
  mutation DeleteCompanyLocations($ids: [ID!]!) {
    softDeleteCompanyLocations(ids: $ids) {
      success
    }
  }
`;