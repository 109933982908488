<vdr-page-block>
  <vdr-action-bar>
    <vdr-ab-left></vdr-ab-left>
  </vdr-action-bar>
</vdr-page-block>
<vdr-page-block>
  <div *ngIf="stockLevels$ | async as stockLevels">
    <ng-template ngFor let-stockLevel [ngForOf]="stockLevels" let-i="index">
      <vdr-card title="{{stockLevel.stockLocation.name}}">
        <vdr-page-block>
          <div class="right-content">
              <button class="button primary" (click)="openDialog(stockLevel.id)">
                <clr-icon shape="plus"></clr-icon>
                Add UOM
              </button>
          </div>
        </vdr-page-block>
        <uoms [updatedData]="updatedData$" [openEditDialog]="openEditDialog" productVariantId="{{params?.id}}" stockLevelId="{{stockLevel.id}}" stockLocationId="{{stockLevel.stockLocation.id}}" [uoms]="uoms$"></uoms>
      </vdr-card>
    </ng-template>
  </div>
</vdr-page-block>