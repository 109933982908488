import { registerPageTab } from '@vendure/admin-ui/core';

import { StockLocationsComponent } from './components/stock-locations/stock-locations.component';

export default [
  registerPageTab({
    location: 'product-variant-detail',
    tab: 'UOMs',
    route: 'uoms',
    component: StockLocationsComponent,
  }),
];