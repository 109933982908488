import { addNavMenuItem } from '@vendure/admin-ui/core'

export default [
  addNavMenuItem(
    {
      id: 'recurring-orders',
      label: 'Recurring Orders',
      routerLink: ['/extensions/recurring-orders/list'],
      icon: 'repeat',
    },
    'companies',
  ),
]
