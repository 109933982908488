import gql from 'graphql-tag';

export const UomFragment = gql`
  fragment MyType on UomType {
    id
    code
    name
    description
    defaultPer
    customFields
  }
  fragment UomItem on Uom {
    id
    type {
      ...MyType
    }
    per
    costBasis
    customFields
    minQty
    canBreakBulk
    enabled
    quantityAvailable
  }
`;

export const UomListItemFragment = gql`
  fragment UomListItem on Uom {
    ...UomItem
    childUnit {
      ...UomItem
    }
  }
${UomFragment}`;

export const GetUomsByStockLocationIdDocument = gql`
  query GetUomsByStockLocationId($stockLocationId: ID!, $productVariantId: ID!) {
    uomsByStockLocationId(stockLocationId: $stockLocationId, productVariantId: $productVariantId) {
      ...UomListItem
    }
  }
${UomListItemFragment}`;

export const SoftDeleteUomDocument = gql`
  mutation SoftDeleteUom($id: ID!) {
    softDeleteUom(id: $id) {
      message
      result
    }
  }
`;
export const SoftUnDeleteUomDocument = gql`
  mutation softUnDeleteUom($id: ID!) {
    softUnDeleteUom(id: $id) {
      message
      result
    }
  }`;