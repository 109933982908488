import gql from 'graphql-tag';

export const GetStockLocationListDocument = gql`
  query GetStockLevels($variantId: ID!) {
    productVariant(id: $variantId) {
      id
      stockLevels {
        id
        createdAt
        updatedAt
        stockOnHand
        stockAllocated
        stockLocationId
        stockLocation {
            id
            createdAt
            updatedAt
            name
        }
      }
    }
  }
`;

export const GetUomsDocument = gql`
  query GetUomTypes {
    uomTypeList {
      id
      code
      name
      description
      defaultPer
    }
  }`;