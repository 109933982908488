import { addNavMenuSection, addNavMenuItem } from '@vendure/admin-ui/core'


// Removes all listed menu items from menu
// by adding incorrect(__disable__) permission
export default [
  addNavMenuSection(
    {
      id: 'customers',
      label: 'customers',
      requiresPermission: '__disable__',
      items: [],
    },
    'catalog',
  ),
  addNavMenuSection(
    {
      id: 'marketing',
      label: 'marketing',
      requiresPermission: '__disable__',
      items: [],
    },
    'catalog',
  ),
  addNavMenuItem(
    {
      id: 'sellers',
      label: 'sellers',
      routerLink: ['/settings', 'sellers'],
      requiresPermission: '__disable__',
    },
    'settings',
  ),
  addNavMenuItem(
    {
      id: 'countries',
      label: 'countries',
      routerLink: ['/settings', 'countries'],
      requiresPermission: '__disable__',
    },
    'settings',
  ),
  addNavMenuItem(
    {
      id: 'tax-categories',
      label: 'tax-categories',
      routerLink: ['/settings', 'tax-categories'],
      requiresPermission: '__disable__',
    },
    'settings',
  ),
  addNavMenuItem(
    {
      id: 'tax-rates',
      label: 'tax-rates',
      routerLink: ['/settings', 'tax-rates'],
      requiresPermission: '__disable__',
    },
    'settings',
  ),
  addNavMenuItem(
    {
      id: 'zones',
      label: 'zones',
      routerLink: ['/settings', 'zones'],
      requiresPermission: '__disable__',
    },
    'settings',
  ),
]
