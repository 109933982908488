import { ChangeDetectionStrategy, Component, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import {
  SharedModule,
  DataService,
} from '@vendure/admin-ui/core'
import { UomsComponent } from '../uoms/uoms.component'
import { Observable, Subscription, BehaviorSubject } from 'rxjs'
import { GetStockLocationListDocument, GetUomsDocument } from './providers'
import { UomLineComponent } from '../uom/uom.component'
import { ModalService } from '../../modal.service'
import { ModalModule } from '../../modal.module'

@Component({
  selector: 'stock-locations',
  templateUrl: './stock-locations.component.html',
  styleUrls: ['./stock-locations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, ModalModule, UomsComponent, UomLineComponent],
})
export class StockLocationsComponent implements OnInit, OnDestroy {
  private stockLevelsSubject = new BehaviorSubject<any[]>([])
  private updatedDataSubject = new BehaviorSubject<any[]>([])
  uoms$: any[]
  stockLevels$: Observable<any[]> = this.stockLevelsSubject.asObservable()
  params: { id: string; productId: string } | undefined
  private paramsSubscription: Subscription | undefined
  private uomSubscription: Subscription | undefined
  private levelSubscription: Subscription | undefined
  updatedData$: Observable<any> = this.updatedDataSubject.asObservable()
  constructor(private route: ActivatedRoute, private dataService: DataService, private modalService: ModalService) {}
  ngOnInit() {
    this.paramsSubscription = this.route.parent?.params.subscribe((item: any) => {
      this.params = item
    })
    this.getStocks()
    this.getUoms()
  }

  openDialog(id: string) {
    this.modalService.openModal({
      uoms: this.uoms$,
      stockLevel: id,
      onSave: this.onUomAdd,
    })
  }
  onUomUpdate = (uom: any, updatedId: string, uomId: string) => {
    this.updatedDataSubject.next({
      uom,
      updatedId,
      uomId,
    } as any)
  }
  onUomAdd = (uom: any, stockLevelId: string) => {
    this.updatedDataSubject.next({
      uom,
      stockLevelId,
    } as any)
  }
  openEditDialog = (edit: any, id: string, uomId: string) => {
    this.modalService.openModal({
      uoms: this.uoms$,
      stockLevel: id,
      uomId,
      edit,
      onSave: this.onUomUpdate,
    })
  }

  getUoms() {
    this.uomSubscription = this.dataService
      .query(GetUomsDocument, { options: {} })
      .single$.subscribe(({ uomTypeList }: any) => {
        this.uoms$ = uomTypeList
      })
  }
  getStocks() {
    this.levelSubscription = this.dataService
      .query(GetStockLocationListDocument, {
        variantId: this.params?.id,
      })
      .single$.subscribe(({ productVariant }: any) => {
        const currentData = this.stockLevelsSubject.value
        const newData = [...currentData, ...productVariant.stockLevels]
        this.stockLevelsSubject.next(newData)
      })
  }
  ngOnDestroy() {
    this.paramsSubscription?.unsubscribe()
    this.uomSubscription?.unsubscribe()
    this.levelSubscription?.unsubscribe()
  }
}
