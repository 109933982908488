import gql from 'graphql-tag';

const CompanyOrderFragment = gql`
  fragment CompanyOrderFragment on CompanyOrder {  
    id
    order {
      id
      code
      total
      orderPlacedAt
      totalWithTax
      totalQuantity
      taxSummary {
        description
        taxBase
        taxRate
        taxTotal
      }
      state
      active
    }
    location {
      id
      name
    }
    company {
      id
      name
    }
    uomData
    updatedAt
    createdAt
  }
`;

export const getCompanyOrderListDocument = gql`
  query GetCompanyOrders($options: CompanyOrderListOptions) {
    companyOrders(options: $options) {
      items {
        ...CompanyOrderFragment
      }
      totalItems
    }
  }
${CompanyOrderFragment}`;

export const DeleteCompanyOrdersDocument = gql`
  mutation DeleteCompanyOrders($ids: [ID!]!) {
    deleteCompanyOrders(ids: $ids) {
      success
    }
  }
`;