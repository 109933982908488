import { addNavMenuSection } from '@vendure/admin-ui/core'

export default [
  addNavMenuSection(
    {
      id: 'stock-supplier',
      label: 'Stock Supplier',
      displayMode: 'settings',
      collapsible: true,
      items: [{
        id: 'stock-supplier-list',
        label: 'Supplier List',
        routerLink: ['/extensions/stock-supplier/list'],
        icon: 'list',
      }],
    },
    'catalog',
  ),
]