import {
  addNavMenuSection,
  addNavMenuItem,
  registerBulkAction,
  DataService,
  ModalService,
  NotificationService,
  DeletionResult
} from '@vendure/admin-ui/core'
import { DeleteCompaniesDocument } from './components/companies/companies.providers';
import { DeleteCompanyLocationsDocument } from './components/company-locations/company-locations.providers';
import { RemoveCompanyUsersDocument } from './components/company-users/company-users.providers';
import { DeleteCompanyOrdersDocument } from './components/company-orders/company-orders.providers';
import { DeleteCompaniesResponse,
  DeleteCompanyLocationsResponse,
  DeleteCompanyOrdersResponse,
  RemovalResult,
  RemoveCompanyUsers
} from './types';
import removeMenuProviders from './remove-menu'

export default [
  addNavMenuSection(
    {
      id: 'companies',
      label: 'Companies',
      displayMode: 'settings',
      collapsible: true,
      items: [
        {
          id: 'company-list',
          label: 'Companies',
          routerLink: ['/extensions/companies/list'],
          icon: 'organization',
        },
        {
          id: 'company-user-list',
          label: 'Company Users',
          routerLink: ['/extensions/companies/users'],
          icon: 'users',
        },
        {
          id: 'company-location-list',
          label: 'Company Locations',
          routerLink: ['/extensions/companies/locations'],
          icon: 'map-marker',
        },
        {
          id: 'company-order-list',
          label: 'Company Orders',
          routerLink: ['/extensions/companies/orders'],
          icon: 'shopping-cart',
        },
        {
          id: 'company-pricing-library',
          label: 'Pricing Library',
          routerLink: ['/extensions/companies/pricing-library'],
          icon: 'library',
        },
      ],
    },
    'catalog',
  ),
  addNavMenuItem(
    {
      id: 'report',
      label: 'Reports',
      routerLink: ['/extensions/companies/report'],
      icon: 'file',
    },
    'sales',
  ),
  ...removeMenuProviders,
  registerBulkAction({
    location: 'companies',
    label: 'Delete',
    icon: 'trash',
    iconClass: 'is-danger',
    onClick: ({ injector, selection, hostComponent, clearSelection }) => {
      const dataService = injector.get(DataService);
      const modalService = injector.get(ModalService);
      const notificationService = injector.get(NotificationService);
      modalService
        .dialog({
          title: 'Delete ' + (selection.length > 1 ? `${selection.length} companies?` : 'one company?'),
          translationVars: {
            count: selection.length,
          },
          buttons: [
            { type: 'secondary', label: 'Cancel' },
            { type: 'danger', label: 'Delete', returnValue: true },
          ],
        })
        .subscribe(result => {
          if (!result) return
          dataService
            .mutate<DeleteCompaniesResponse>(DeleteCompaniesDocument, { ids: selection.map(item => item.id) })
            .subscribe((result) => {
              const deleted = result.deleteCompanies.filter(item =>
                item.result === DeletionResult.DELETED
              ).length
              if (!!deleted) {
                const message = 'Successfully deleted ' + (deleted > 1 ? `${deleted} companies.` : 'one company.')
                notificationService.success(message);
              }
              hostComponent.refresh();
              clearSelection();
            });
        });
    },
  }),
  registerBulkAction({
    location: 'company-locations',
    label: 'Delete',
    icon: 'trash',
    iconClass: 'is-danger',
    onClick: ({ injector, selection, hostComponent, clearSelection }) => {
      const dataService = injector.get(DataService);
      const modalService = injector.get(ModalService);
      const notificationService = injector.get(NotificationService);
      modalService
        .dialog({
          title: 'Delete ' + (selection.length > 1 ? `${selection.length} company locations?` : 'one company location?'),
          translationVars: {
            count: selection.length,
          },
          buttons: [
            { type: 'secondary', label: 'Cancel' },
            { type: 'danger', label: 'Delete', returnValue: true },
          ],
        })
        .subscribe(result => {
          if (!result) return
          dataService
            .mutate<DeleteCompanyLocationsResponse>(DeleteCompanyLocationsDocument, { ids: selection.map(item => item.id) })
            .subscribe((result) => {
              const deleted = result.softDeleteCompanyLocations.filter(item => item.success).length
              if (!!deleted) {
                const message = 'Successfully deleted ' + (deleted > 1 ? `${deleted} company locations.` : 'one company location.')
                notificationService.success(message);
              }
              hostComponent.refresh();
              clearSelection();
            });
        });
    },
  }),
  registerBulkAction({
    location: 'company-users',
    label: 'Delete',
    icon: 'trash',
    iconClass: 'is-danger',
    onClick: ({ injector, selection, hostComponent, clearSelection }) => {
      const dataService = injector.get(DataService);
      const modalService = injector.get(ModalService);
      const notificationService = injector.get(NotificationService);
      modalService
        .dialog({
          title: 'Delete ' + (selection.length > 1 ? `${selection.length} company users?` : 'one company user?'),
          translationVars: {
            count: selection.length,
          },
          buttons: [
            { type: 'secondary', label: 'Cancel' },
            { type: 'danger', label: 'Delete', returnValue: true },
          ],
        })
        .subscribe(result => {
          if (!result) return
          dataService
            .mutate<RemoveCompanyUsers>(RemoveCompanyUsersDocument, { input: selection.map(item => ({ userId: item.id })) })
            .subscribe((result) => {
              let deleted = 0
              const errors: string[] = []
              result.removeCompanyUsers.forEach(item => {
                if (item.result === RemovalResult.REMOVED) {
                  deleted++;
                } else if (item.message) {
                  errors.push(item.message);
                }
              });

              if (!!deleted) {
                const message = 'Successfully deleted ' + (deleted > 1 ? `${deleted} company users.` : 'one company user.');
                notificationService.success(message);
              }
              if (0 < errors.length) {
                notificationService.error(errors.join('\n'));
              }
              hostComponent.refresh();
              clearSelection();
            });
        });
    },
  }),
  registerBulkAction({
    location: 'company-orders',
    label: 'Delete',
    icon: 'trash',
    iconClass: 'is-danger',
    onClick: ({ injector, selection, hostComponent, clearSelection }) => {
      const dataService = injector.get(DataService);
      const modalService = injector.get(ModalService);
      const notificationService = injector.get(NotificationService);
      modalService
        .dialog({
          title: 'Delete ' + (selection.length > 1 ? `${selection.length} company orders?` : 'one company order?'),
          translationVars: {
            count: selection.length,
          },
          buttons: [
            { type: 'secondary', label: 'Cancel' },
            { type: 'danger', label: 'Delete', returnValue: true },
          ],
        })
        .subscribe(result => {
          if (!result) return
          dataService
            .mutate<DeleteCompanyOrdersResponse>(DeleteCompanyOrdersDocument, { ids: selection.map(item => item.id) })
            .subscribe((result) => {
              const deleted = result.deleteCompanyOrders.filter(item => item.success).length
              if (!!deleted) {
                const message = 'Successfully deleted ' + (deleted > 1 ? `${deleted} company orders.` : 'one company order.')
                notificationService.success(message);
              }
              hostComponent.refresh();
              clearSelection();
            });
        });
    },
  }),
]
