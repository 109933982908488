import { ChangeDetectionStrategy, Component, OnInit, Inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DataService, NotificationService } from '@vendure/admin-ui/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Subscription } from 'rxjs'
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CreateUomDocument, UpdateUomDocument } from './providers'

@Component({
  selector: 'uom',
  templateUrl: './uom.component.html',
  styleUrls: ['./uom.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class UomLineComponent implements OnInit {
  myForm: FormGroup

  isEditMode: boolean = false

  private subscribtion: Subscription | undefined

  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA)
    public modalData: {
      edit?: any
      stockLevel: string
      uomId?: string
      uoms: any[]
      onSave: (uom: any, id: string, uomId?: string) => void
    },
    private dialogRef: MatDialogRef<UomLineComponent>,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.isEditMode = !!this.modalData.edit
    const form = {
      per: [1, Validators.required],
      costBasis: [null, [Validators.required]],
      stockLevelId: this.modalData.stockLevel,
      uomId: this.modalData.uomId,
      uomTypeId: [null, Validators.required],
      minQty: [1, [Validators.required]],
      enabled: [false, [Validators.required]],
      canBreakBulk: [false, [Validators.required]],
    }
    if (this.isEditMode) {
      form.per[0] = this.modalData.edit.per
      form.costBasis[0] = this.modalData.edit.costBasis
      form.uomTypeId[0] = this.modalData.edit.type.id
      form.minQty[0] = this.modalData.edit.minQty
      form.enabled[0] = this.modalData.edit.enabled
      form.canBreakBulk[0] = this.modalData.edit.canBreakBulk
    }
    this.myForm = this.fb.group(form)

    if (this.isEditMode) {
      // this.myForm.controls.per.disable()
      // this.myForm.controls.costBasis.disable()
      this.myForm.controls.uomTypeId.disable()
    }
  }

  onUomTypeChange(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value
    const uom = this.modalData.uoms.find(({ id }: any) => id == selectedValue)
    const value = this.myForm.value
    value.name = uom.name
    this.myForm.setValue(value)
  }

  create() {
    const { uomId, ...data } = this.myForm.value
    this.subscribtion = this.dataService
      .mutate(CreateUomDocument, { input: data })
      .subscribe(({ createUomType }: any) => {
        this.closeDialog()
        if (!createUomType.errorCode) {
          this.modalData.onSave(createUomType, this.myForm.value.stockLevelId, this.myForm.value.uomId)
        } else {
          this.notificationService.error(createUomType.message)
        }
      })
  }
  update() {
    this.subscribtion = this.dataService
      .mutate(UpdateUomDocument, {
        id: this.modalData.edit.id,
        input: {
          minQty: this.myForm.value.minQty,
          per: this.myForm.value.per,
          costBasis: this.myForm.value.costBasis,
          enabled: this.myForm.value.enabled,
          canBreakBulk: this.myForm.value.canBreakBulk,
        },
      })
      .subscribe(({ updateUomType }: any) => {
        this.closeDialog()
        if (!updateUomType.errorCode) {
          this.modalData.onSave(updateUomType, this.myForm.value.stockLevelId,  this.myForm.value.uomId)
        } else {
          this.notificationService.error(updateUomType.message)
        }
      })
  }

  onSubmit() {
    if (!this.isEditMode) {
      this.create()
    } else {
      this.update()
    }
  }

  closeDialog(): void {
    this.dialogRef.close()
    this.subscribtion?.unsubscribe()
  }
}
