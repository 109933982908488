<div class="modal">
  <div class="modal-body">
    <form [formGroup]="myForm" class="form" (submit)="onSubmit()">
      <div class="form-group">
        <label for="per">Per Units:</label>
        <input type="number" name="per" id="per" formControlName="per" placeholder="Per Units" />
      </div>
      <div class="form-group">
        <label for="uomTypeId">Uom Type:</label>
        <select id="uomTypeId" formControlName="uomTypeId" name="uomTypeId">
          <option *ngFor="let uom of modalData.uoms" [value]="uom.id">{{uom.code}}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="costBasis">Cost Basis:</label>
        <input
          type="number"
          name="costBasis"
          formControlName="costBasis"
          placeholder="Cost basis" />
      </div>
      <div class="form-group">
        <label for="minQty">Minimum Purchase Quantity:</label>
        <input
          type="number"
          name="minQty"
          formControlName="minQty"
          placeholder="Minimum Purchase quantity" />
      </div>
      <div class="form-group">
        <label for="enabled">Enabled:</label>
        <div class="flex checkbox-container">
          <input
            id="enabled"
            type="checkbox"
            clrCheckbox
            formControlName="enabled"
            name="enabled" />
        </div>
      </div>
      <div class="form-group">
        <label for="canBreakBulk">Can Break Bulk:</label>
        <div class="flex checkbox-container">
          <input
            id="canBreakBulk"
            type="checkbox"
            clrCheckbox
            formControlName="canBreakBulk"
            name="canBreakBulk" />
        </div>
      </div>
      
      <div class="form-group action-buttons">
        <button class="button" type="button" (click)="closeDialog()">Close</button>
        <button class="button primary" [disabled]="!myForm.valid">{{isEditMode ? 'Update' : 'Add' }} </button>
      </div>
    </form>
  </div>
</div>