export const extensionRoutes = [  {
    path: 'extensions/advanced-search',
    loadChildren: () => import('./extensions/advanced-search-plugin/advanced-search-ui-lazy.module').then(m => m.AdvancedSearchUiLazyModule),
  },
  {
    path: 'extensions/companies',
    loadChildren: () => import('./extensions/b2b-plugin/routes'),
  },
  {
    path: 'extensions/stock-supplier',
    loadChildren: () => import('./extensions/stock-supplier-plugin/routes'),
  },
  {
    path: 'extensions/recurring-orders',
    loadChildren: () => import('./extensions/recurring-orders-plugin/routes'),
  },
  {
    path: 'extensions/import-products',
    loadChildren: () => import('./extensions/import-products-plugin/routes'),
  },
  {
    path: 'extensions/update-products',
    loadChildren: () => import('./extensions/import-products-plugin/routes'),
  }];
