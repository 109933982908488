import gql from 'graphql-tag';

export const CompanyUserFragment = gql`
  fragment CompanyUserFragment on CompanyUser {
    id
    createdAt
    deletedAt
    lastLogin
    title
    firstName
    lastName
    phoneNumber
    identifier
    disabled
    marketingOptIn
    doNotReceiveOrderNotifications
    company {
      id
      name
    }
    role {
      code
      description
    }
    avatar {
      name
      type
      mimeType
      width
      height
      source
      preview
    }
  }
`;

export const getCompanyUserListDocument = gql`
  query GetCompanyUsers($options: CompanyUserListOptions) {
    companyUsers(options: $options) {
      items {
        ...CompanyUserFragment
      }
      totalItems
    }
  }
${CompanyUserFragment}`;

export const RemoveCompanyUsersDocument = gql`
  mutation RemoveCompanyUsers($input: [RemoveCompanyUserInput!]!) {
    removeCompanyUsers(input: $input) {
      result
      message
    }
  }
`;