// graphql.service.ts
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { SoftDeleteUomDocument, SoftUnDeleteUomDocument } from './components/uoms/providers';

@Injectable({
  providedIn: 'root',
})
export class GraphqlService {
  constructor(private apollo: Apollo) {}

  softDeleteUom(id: string) {
    return this.apollo.mutate({
      mutation: SoftDeleteUomDocument,
      variables: { id },
    });
  }

  softUnDeleteUom(id: string) {
    return this.apollo.mutate({
      mutation: SoftUnDeleteUomDocument,
      variables: { id },
    });
  }
}
